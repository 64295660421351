<template>
    <registration-form is-choice
        route="register"/>
</template>

<script>
import RegistrationForm from '@components/auth/RegistrationForm.vue';

export default {
    name: 'RegisterChoice',

    components: { RegistrationForm },
};
</script>
